import React, { useState } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdOutlineRestaurantMenu } from 'react-icons/md';
import images from '../../constants/images';
import './Navbar.css';

// NAVBAR
const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const handleMenuClick = () => {
    setToggleMenu(false); // close the menu mobile
  };

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <img src={images.guichgourmet} alt="app logo" />
      </div>
      <ul className="app__navbar-links">
        <li className="p__opensans">
          <a href="#home">Accueil</a>
        </li>
        <li className="p__opensans">
          <a href="#about">A propos</a>
        </li>
        <li className="p__opensans">
          <a href="#menu">Menu</a>
        </li>
        <li className="p__opensans">
          <a href="#awards">Récompenses</a>
        </li>
        <li className="p__opensans">
          <a href="#galerie">Galerie</a>
        </li>
        <li className="p__opensans">
          <a href="#contact">Contact</a>
        </li>
      </ul>

      {/* NAVBAR MOBILE */}
      <div className="app__navbar-smallscreen">
        <GiHamburgerMenu
          color="#000000"
          fontSize={27}
          onClick={() => {
            setToggleMenu(true);
          }}
        />

        {toggleMenu && (
          <div className="app__navbar-smallscreen-overlay flex__center slide-bottom">
            <MdOutlineRestaurantMenu
              fontSize={27}
              className="overlay__close"
              onClick={() => {
                setToggleMenu(false);
              }}
            />
            <ul className="app__navbar-smallscreen_links">
              <li className="p__opensans">
                <a href="#home" onClick={handleMenuClick}>
                  Accueil
                </a>
              </li>
              <li className="p__opensans">
                <a href="#about" onClick={handleMenuClick}>
                  A propos
                </a>
              </li>
              <li className="p__opensans">
                <a href="#menu" onClick={handleMenuClick}>
                  Menu
                </a>
              </li>
              <li className="p__opensans">
                <a href="#awards" onClick={handleMenuClick}>
                  Récompenses
                </a>
              </li>
              <li className="p__opensans">
                <a href="#galerie" onClick={handleMenuClick}>
                  Galerie
                </a>
              </li>
              <li className="p__opensans">
                <a href="#contact" onClick={handleMenuClick}>
                  Contact
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
