import images from './images';

const wines = [
  {
    title: 'Chapel Hill Shiraz',
    price: '€56',
    tags: 'AU | Bouteille',
  },
  {
    title: 'Catena Malbee',
    price: '€59',
    tags: 'AU | Bouteille',
  },
  {
    title: 'La Vieillw Rose',
    price: '€44',
    tags: 'FR | 750 ml',
  },
  {
    title: 'Rhino Pale Ale',
    price: '€31',
    tags: 'CA | 750 ml',
  },
  {
    title: 'Irish Guinness',
    price: '€26',
    tags: 'IE | 750 ml',
  },
];

const cocktails = [
  {
    title: 'Aperol Sprtiz',
    price: '€10',
    tags: 'Aperol | Prosecco Villa Marchesi | soda | 30 ml',
  },
  {
    title: "Dark 'N' Stormy",
    price: '€12',
    tags: 'Rhum Ambré | Ginger beer | Tranche de citron vert',
  },
  {
    title: 'Daiquiri',
    price: '€10',
    tags: 'Rhum | Jus de citron | Sucre',
  },
  {
    title: 'Old Fashioned',
    price: '€13',
    tags: 'Bourbon | Sucre roux | Angostura Bitters',
  },
  {
    title: 'Almondo',
    price: '€12',
    tags: 'Amaretto | jus de citron vert | Sirop de sucre de canne | Bitter | Fleurs séchées',
  },
];
const appetizers = [
  {
    title: "Soupe à l'Oignon",
    price: '€9,50',
    tags: 'Oignons jaunes caramélisés | bouillon de bœuf maison | vin blanc | croûtons | fromage Gruyère',
  },
  {
    title: 'Salade de Chèvre Chaud',
    price: '€11',
    tags: 'Mélange de roquette et mâche | betteraves rôties | noix torréfiées | crottin de chèvre grillé | pain de campagne toasté | vinaigrette au miel et moutarde de Dijon',
  },
  {
    title: 'Terrine de Foie Gras',
    price: '€14',
    tags: 'Foie gras de canard maison | chutney de figues | gelée de Sauternes | brioche toastée',
  },
];

const mainCourses = [
  {
    title: 'Bœuf Bourguignon',
    price: '€22,50',
    tags: 'Bœuf charolais braisé | carottes | oignons | champignons de Paris | lardons fumés | vin rouge de Bourgogne | purée de pommes de terre',
  },
  {
    title: "Saumon Grillé à l'Aneth",
    price: '€20',
    tags: 'Filet de saumon Atlantique | aneth frais | citron | légumes de saison | pommes de terre vapeur | sauce à la crème et à l’aneth',
  },
  {
    title: 'Poulet Rôti aux Herbes',
    price: '€18',
    tags: 'Poulet fermier rôti | thym | romarin | ail | purée de pommes de terre | petits pois frais | jus de volaille réduit',
  },
];

const desserts = [
  {
    title: 'Tarte Tatin',
    price: '€8',
    tags: 'Pommes caramélisées | pâte feuilletée | glace vanille',
  },
  {
    title: 'Crème Brûlée à la Vanille',
    price: '€7,50',
    tags: 'Crème onctueuse | vanille de Madagascar | sucre caramélisé',
  },
  {
    title: 'Moelleux au Chocolat',
    price: '€9',
    tags: 'Chocolat noir 70% | beurre demi-sel | crème anglaise | éclats de noisettes',
  },
];

const awards = [
  {
    imgUrl: images.award02,
    title: 'Gault & Millau',
    subtitle:
      'Une expérience culinaire remarquable avec des saveurs innovantes et une présentation impeccable.',
  },
  {
    imgUrl: images.award01,
    title: 'Guide Michelin',
    subtitle:
      "Une étoile méritée pour Guiche & Gourmet où chaque plat est une œuvre d'art et les cocktails sont une révélation.",
  },
  {
    imgUrl: images.award05,
    title: 'Zagat',
    subtitle:
      'Des plats délicieux et des cocktails créatifs dans un cadre impeccable. Une expérience culinaire à ne pas manquer.',
  },
  {
    imgUrl: images.award03,
    title: 'Le Food Guide ',
    subtitle:
      'Une cuisine raffinée et des cocktails élégants dans une ambiance chaleureuse et accueillante.',
  },
];

export default { wines, cocktails, appetizers, mainCourses, desserts, awards };
