import React from 'react';
import { images } from '../../constants';
import { SubHeading } from '../../components';

const FindUs = () => (
  <div className="app__bg app__wrapper section__padding" id="contact">
    <div className="app_wrapper-info">
      <SubHeading title="Contact" />
      <h1 className="headtext__cormorant" style={{ marginBottom: '3rem' }}>
        Nos cordonnées
      </h1>
      <div className="app__wrapper-content">
        <p className="p__opensans">
          Guiche & Gourmet 123 Rue des Saveurs 75001 Paris France
        </p>
        <p
          className="p__cormorant"
          style={{ color: '#ba3a0b', margin: '2rem 0' }}
        >
          Heures d'ouverture
        </p>
        <p className="p__opensans">Lundi - Vendredi: 10h00 - 23h00</p>
        <p className="p__opensans">Samedi - Dimanche: 10h00 - 01h00</p>
      </div>
      {/* <button className="custom__button" style={{ marginTop: '2rem' }}>
        Visit Us
      </button> */}
    </div>
    <div className="app__wrapper_img">
      <img src={images.findus} alt="findus" />
    </div>
  </div>
);

export default FindUs;
