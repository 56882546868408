import bg from '../assets/bg.webp';
import chef from '../assets/chef.webp';
import G from '../assets/G.webp';
import gallery01 from '../assets/gallery01.webp';
import gallery02 from '../assets/gallery02.webp';
import gallery03 from '../assets/gallery03.webp';
import gallery04 from '../assets/gallery04.webp';
import gallery05 from '../assets/gallery05.webp';
import gallery06 from '../assets/gallery06.webp';
import gallery07 from '../assets/gallery07.webp';
import gallery08 from '../assets/gallery08.webp';
import gallery09 from '../assets/gallery09.webp';
import gallery10 from '../assets/gallery10.webp';
import gallery11 from '../assets/gallery11.webp';
import knife from '../assets/knife.webp';
import logo from '../assets/logo.webp';
import cocktail from '../assets/cocktail.webp';
import overlaybg from '../assets/overlaybg.webp';
import spoon from '../assets/spoon.svg';
import welcome from '../assets/welcome.webp';
import findus from '../assets/findus.webp';
import laurels from '../assets/laurels.webp';
import award01 from '../assets/award01.webp';
import award02 from '../assets/award02.webp';
import award03 from '../assets/award03.webp';
import award05 from '../assets/award05.webp';
import sign from '../assets/sign.webp';
import quote from '../assets/quote.webp';
import guichgourmet from '../assets/Guiche&Gourmet.webp';
import appetizer from '../assets/appetizer.webp';
import mainCourse from '../assets/main-course.webp';
import dessert from '../assets/dessert.webp';

export default {
  bg,
  chef,
  G,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  gallery05,
  gallery06,
  gallery07,
  gallery08,
  gallery09,
  gallery10,
  gallery11,
  knife,
  logo,
  cocktail,
  overlaybg,
  spoon,
  welcome,
  findus,
  laurels,
  award01,
  award02,
  award03,
  award05,
  sign,
  quote,
  guichgourmet,
  appetizer,
  mainCourse,
  dessert,
};
